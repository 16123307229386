import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import CategoryList from "~/components/category-list"
import ProductList from "~/components/product-list"
import PageHeading from "~/components/styled/page-heading"
import SEO from "~/components/seo"
import CategoryRename from "~/components/category-rename"
import CategoryMetaDescription from  "~/components/category-meta-description"
import GoogleAdsense from "~/components/google-adsense"

const CategoryPage = ({ data, location }) => {
  const categoryName = CategoryRename(data.strapiCategory.name)
  const flatProducts = data.allStrapiProduct.edges.map(({ node }) => node)
  const seo = {
    title: CategoryRename(data.strapiCategory.name, true),
    description: CategoryMetaDescription(flatProducts)
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <CategoryList slug={"0vgnv0po"} />

      <div className="mt-8 mb-10">
        <PageHeading>{categoryName}</PageHeading>
        <ProductList products={flatProducts} />
      </div>

      <GoogleAdsense slot="5243618393" responsive="true" />

      <div className="mb-16 mt-8">
        <CategoryList slug={"0vgnv0po"} showMenu={true} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllProductQuery {
    strapiCategory(slug: {eq: "0vgnv0po"}) {
      name
    }

    allStrapiProduct(
      filter: {categories: {elemMatch: {slug: {eq: "0vgnv0po"}}}}
      sort: {fields: created_at, order: DESC}
    ) {
      edges {
        node {
          name
          slug
          price
          originalPrice
          sold
          ImageDesc {
            desc
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 360
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                  quality: 75
                )
              }
            }
          }
        }
      }
    }
  }
`

export default CategoryPage

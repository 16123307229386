import ProductRename from "~/components/product-rename"
import ProductBrandName from "~/components/product-brand-name"

const maxMetaDescriptionLength = 160

const CategoryMetaDescription = (products) => {
  let tags = []
  let names = []

  let description = ''
  for (let i=0; i<products.length; i++) {
    if (products[i].name && products[i].name.trim() != '') {
      let productNames = ProductRename(products[i].name.trim(), true)
      let newTag = productNames.tag || ''
      let newBrandName = ProductBrandName(productNames.name)
      if (newBrandName) {
        names.push(newBrandName)
      }
      if (!tags || tags.indexOf(newTag) !== -1) {
        continue
      }
      if (description != '') {
        description += ', '
      }
      description += newTag
      tags.push(newTag)
      if (description.length >= maxMetaDescriptionLength) {
        break
      }
    }
  }

  if (description.length < maxMetaDescriptionLength) {
    for (let i=0; i<names.length; i++) {
      if (description != '') {
        description += ', '
      }
      description += names[i]
      if (description.length >= maxMetaDescriptionLength) {
        break
      }
    }
  }

  return description
}

export default CategoryMetaDescription
